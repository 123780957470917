import * as React from 'react';
import Layout from '../components/GeneralComponent/layout';

export default () => (
    <Layout>
        <div id='content-row'>
            <div className='row-container'>
                <div className='container-fluid'>
                    <div className='content-inner row-fluid'>
                    <h1>NOT FOUND</h1>
                    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
);
